import React from 'react'
import Footer from '../components/Footer/Footer'
import Header from '../components/Header/Header'
import Terms from '../components/TermsandCondition/Terms'
const TermsandConditionPage = () => {
  return (
   <>
   <Header/>
   <Terms/>
   <Footer/>
   </>
  )
}

export default TermsandConditionPage