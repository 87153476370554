import axios from "axios";

export const PrivacypolicyAPI={
    get:function() {
       return axios({
            method:'GET',
            url: `${process.env.REACT_APP_API_URL}/get-privacy`,
        })
    }
}
