import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import * as $ from 'jquery'
import { SnackbarProvider } from 'notistack';
import { UserProvider } from './components/Context/UserContext';
import { CanSeeProfileProvider } from './components/Context/CanSeeProfileContext';
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(

  <BrowserRouter>
    {/* <React.StrictMode> */}
      <SnackbarProvider maxSnack={3}>
      <UserProvider>
      <CanSeeProfileProvider>

        <App />
  </CanSeeProfileProvider>
  </UserProvider>

      </SnackbarProvider>
    {/* </React.StrictMode> */}
  </BrowserRouter>

  ,
  document.getElementById('root'),
  window.jQuery = window.$ = $

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
