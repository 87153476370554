import React from 'react'
import Footer from '../components/Footer/Footer'
import Header from '../components/Header/Header'
import PrivacyPolicy from '../components/PrivacyPolicy/PrivacyPolicy'
const PrivacyPolicyPage = () => {
  return (
   <>
   <Header/>
   <PrivacyPolicy/>
   <Footer/>
   </>
  )
}

export default PrivacyPolicyPage