import axios from "axios";

export const PackageAPI={
    get:function() {
       return axios({
            method:'GET',
            url: `${process.env.REACT_APP_API_URL}/get-packages`,
        })
    }
}
