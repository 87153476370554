import React from 'react'
import Footer from '../components/Footer/Footer'
import Header from '../components/Header/Header'
import Myprofile from '../components/Myprofile/Myprofile'

const Myprofilepage = () => {
  return (
   <>
     <Header/>
     <Myprofile/>
     <Footer/>
   </>
  )
}

export default Myprofilepage