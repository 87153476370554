import * as React from 'react';
import { useEffect, useState, useContext } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import { UserContext } from "../Context/UserContext";
import { UserprofileAPI } from "./UseprofileAPI";
import axios from 'axios';
import './Userprofile.css'

const drawerWidth = 240;

export default function Sidebar({ children , handleChange}) {

    const [loading, setLoading] = useState(false);
    const [gotrafilter, setGotrafilter] = useState([]);
    const [castefilter, setCastefilter] = useState([]);
    const [religionfilter, setReligionfilter] = useState([]);
    const [educationfilter, setEducationfilter] = useState([]);
    const [employmentfilter, setEmploymentfilter] = useState([]);
    const [countryfilter, setCountryfilter] = useState([]);
    const [cityfilter, setCityfilter] = useState([]);

    const [ token] = useContext(UserContext);

    const [showAllCountry, setShowAllCountry] = useState(false);


    useEffect(() => {
        setLoading(true);
        UserprofileAPI.getgotra(token)
            .then((res) => {
                let response = res.data.data.gotras;
                console.log(response)
                setGotrafilter(response)
                setLoading(false);
            }
            ).catch((err) => {
                console.log(err, "error message")
                setLoading(true);
            })

        UserprofileAPI.getcaste(token)
            .then((res) => {
                let response = res.data.data.castes;
                console.log(response)
                setCastefilter(response)
                setLoading(false);
            }
            ).catch((err) => {
                console.log(err, "error message")
                setLoading(true);
            })

        UserprofileAPI.getreligion(token)
            .then((res) => {
                let response = res.data.data.religions;
                console.log(response)
                setReligionfilter(response)
                setLoading(false);
            }
            ).catch((err) => {
                console.log(err, "error message")
                setLoading(true);
            })

        UserprofileAPI.geteducation(token)
            .then((res) => {
                let response = res.data.data.educations;
                console.log(response)
                setEducationfilter(response)
                setLoading(false);
            }
            ).catch((err) => {
                console.log(err, "error message")
                setLoading(true);
            })

        UserprofileAPI.getemployment(token)
            .then((res) => {
                let response = res.data.data.employment_types;
                console.log(response)
                setEmploymentfilter(response)
                setLoading(false);
            }
            ).catch((err) => {
                console.log(err, "error message")
                setLoading(true);
            })

        UserprofileAPI.getcountry(token)
            .then((res) => {
                let response = res.data.data.countries;
                console.log(response)
                setCountryfilter(response)
                setLoading(false);
            }
            ).catch((err) => {
                console.log(err, "error message")
                setLoading(true);
            })

        UserprofileAPI.getcity(token)
            .then((res) => {
                let response = res.data.data.cities;
                console.log(response)
                setCityfilter(response)
                setLoading(false);
            }
            ).catch((err) => {
                console.log(err, "error message")
                setLoading(true);
            })




    }, [])

    console.log(handleChange)
    return (



        <Box sx={{ display: 'flex' }} >
            {/* <CssBaseline /> */}
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    height:'500px',
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                        position: "absolute"
                    },
                }}
                variant="permanent"
                anchor="left"
            >
                <div className='filter-checkbox'>

                    <div className="filter-panel">
                        <h5 className="filter-header">Gotra</h5>
                        <select className="select" aria-label="Default select example" name='gothra' onChange={handleChange}>
                            <option> = Select Gotra</option>
                            {
                                gotrafilter.map((elem) => {
                                    return (
                                        <>
                                            <option value={elem.title}> {elem.title}</option>
                                        </>
                                    )
                                }
                                )
                            }
                        </select>
                    </div>

                    <div className="filter-panel">
                        <h5 className="filter-header">Caste</h5>
                        <select className="select" aria-label="Default select example" name='caste' onChange={handleChange}>
                            <option> = Select Caste</option>
                            {
                                castefilter.map((elem) => {
                                    return (
                                        <>
                                            <option value={elem.title}> {elem.title}</option>
                                        </>
                                    )
                                }
                                )
                            }
                        </select>
                    </div>

                    <div className="filter-panel">
                        <h5 className="filter-header">Religion</h5>
                        <select className="select" aria-label="Default select example" name='religion' onChange={handleChange}>
                            <option> = Select Religion</option>
                            {
                                religionfilter.map((elem) => {
                                    return (
                                        <>
                                            <option value={elem.title}> {elem.title}</option>
                                        </>
                                    )
                                }
                                )
                            }
                        </select>
                    </div>

                    <div className="filter-panel">
                        <h5 className="filter-header">Education</h5>
                        <select className="select" aria-label="Default select example" name='education' onChange={handleChange}>
                            <option> = Select Education</option>
                            {
                                educationfilter.map((elem) => {
                                    return (
                                        <>
                                            <option value={elem.title}> {elem.title}</option>
                                        </>
                                    )
                                }
                                )
                            }
                        </select>
                    </div>

                    <div className="filter-panel">
                        <h5 className="filter-header">Employment</h5>
                        <select className="select" aria-label="Default select example" name='employment_type' onChange={handleChange}>
                            <option> = Select Employment</option>
                            {
                                employmentfilter.map((elem) => {
                                    return (
                                        <>
                                            <option value={elem.title}> {elem.title}</option>
                                        </>
                                    )
                                }
                                )
                            }
                        </select>
                    </div>

                    <div className="filter-panel">
                        <h5 className="filter-header">Country</h5>
                        <select className="select" aria-label="Default select example" name='country' onChange={handleChange}>
                            <option> = Select Country</option>
                            {
                                countryfilter.map((elem) => {
                                    return (
                                        <>
                                            <option value={elem.name}> {elem.name}</option>
                                        </>
                                    )
                                }
                                )
                            }
                        </select>
                    </div>

                    <div className="filter-panel">
                        <h5 className="filter-header">City</h5>
                        <select className="select" aria-label="Default select example" name='location' onChange={handleChange}>
                            <option> = Select City</option>
                            {
                                cityfilter.map((elem) => {
                                    return (
                                        <>
                                            <option value={elem.title}> {elem.title}</option>
                                        </>
                                    )
                                }
                                )
                            }
                        </select>
                    </div>

                </div>

            </Drawer>
            <Box
                component="main"
                sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
            >
                {children}
            </Box>
        </Box >




    );
}























{/* <div className='filter-checkbox'>
                    <div className="filter-panel">
                        <h5 className="filter-header">Gotra</h5>
                        <div className="filter-panel-body">
                            {
                                gotrafilter.map((elem,id) => {
                                    return (
                                        <>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox"  id={`gotra-${id}`} />
                                                <label className="form-check-label" htmlFor={`gotra-${id}`}>
                                                    {elem.title}
                                                </label>
                                            </div></>
                                    )
                                })
                            }
                        </div>
                    </div>

                    <div className="filter-panel">
                        <h5 className="filter-header">Caste</h5>
                        <div className="filter-panel-body">
                            {
                                castefilter.map((elem) => {
                                    return (
                                        <>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox"  id="flexCheckDefault" />
                                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                                    {elem.title}
                                                </label>
                                            </div></>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="filter-panel">
                        <h5 className="filter-header">Religion</h5>
                        <div className="filter-panel-body">
                            {
                                religionfilter.map((elem) => {
                                    return (
                                        <>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox"  id="flexCheckDefault" />
                                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                                    {elem.title}
                                                </label>
                                            </div></>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="filter-panel">
                        <h5 className="filter-header">Education</h5>
                        <div className="filter-panel-body">
                            {
                                educationfilter.map((elem) => {
                                    return (
                                        <>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox"  id="flexCheckDefault" />
                                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                                    {elem.title}
                                                </label>
                                            </div></>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="filter-panel">
                        <h5 className="filter-header">Employment Types</h5>
                        <div className="filter-panel-body">
                            {
                                employmentfilter.map((elem) => {
                                    return (
                                        <>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox"  id="flexCheckDefault" />
                                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                                    {elem.title}
                                                </label>
                                            </div></>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="filter-panel">
                        <h5 className="filter-header">Countries</h5>
                        <div className="filter-panel-body">
                            {
                               showAllCountry===false?
                                countryfilter.slice(0, 5).map((elem) => {
                                    return (
                                        <>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox"  id="flexCheckDefault" />
                                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                                    {elem.name}
                                                </label>
                                            </div>
                                        </>
                                    )
                                })

                                :
                                countryfilter.map((elem) => {
                                    return (
                                        <>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox"  id="flexCheckDefault" />
                                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                                    {elem.name}
                                                </label>
                                            </div>
                                        </>
                                    )
                                })
                            }
                            
                            <a href="#" className="link-primary show-more" onClick={e=>setShowAllCountry(!showAllCountry)}>
                                {showAllCountry?"Show Less":"Show More"} &nbsp;
                            {showAllCountry? <i class="fa-solid fa-angle-up"></i>:<i class="fa-solid fa-angle-down"></i>} 
                            </a>

                        </div>
                    </div>
                    <div className="filter-panel">
                        <h5 className="filter-header">Cities</h5>
                        <div className="filter-panel-body">
                            {
                                cityfilter.map((elem) => {
                                    return (
                                        <>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox"  id="flexCheckDefault" />
                                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                                    {elem.title}
                                                </label>
                                            </div></>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div> */}