import React from 'react'
import Footer from '../components/Footer/Footer'
import Header from '../components/Header/Header'
import SuccessStories from '../components/Success/SuccessStories'
const SuccessStoriesPage = () => {
  return (
    <>
    <Header/>
    <SuccessStories/>
     <Footer/>
    </>
  )
}

export default SuccessStoriesPage