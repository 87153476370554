import React from 'react'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import Package from '../components/Package/Package'

const Packagepage = () => {
  return (
   <>
   <Header/>
   <Package/>
   <Footer/>
   </>
  )
}

export default Packagepage