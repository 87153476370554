import React from 'react'
import Footer from '../components/Footer/Footer'
import Header from '../components/Header/Header'
import UserRegister from '../components/UserRegister/UserRegister'

const UserRegisterPage = () => {
  return (
   <>
   <Header/>
   <UserRegister/>
   <Footer/>
   </>
  )
}

export default UserRegisterPage