import React, { useState, useEffect } from "react";
import Image2 from "../../assets/images/img-1.jpg";
import Image3 from "../../assets/images/img-2.jpg";
import Image4 from "../../assets/images/img-3.jpg";
// import Image18 from "../../assets/images/bg-2.jpg";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Image20 from "../../assets/images/slider-bg-1.jpg";
import { Link } from "react-router-dom";
import { SuccessStoriesAPI } from "../Success/SuccessStoriesAPI";
import parse from 'html-react-parser';
import Skeletonloader from "../skeletonloader/skeletonloader";

const Home = () => {

  const [datas, setDatas] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    SuccessStoriesAPI.get()
      .then((res) => {
        // console.log(res.data.data.testimonials)
        let response = res.data.data.testimonials;
        setDatas(response)
        setLoading(false);
      }
      ).catch((err) => {
        console.log(err, "error message")
        setLoading(false);
      })
  }, [])


  return (
    <>
      <section class="banner mt-4">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-12 col-xl-7">
              <div class="block">
                <div class="divider mb-3"></div>
                <span class="text-uppercase text-sm letter-spacing text-white">
                  <strong>Find your soulmate</strong>
                </span>
                <h1 class="mb-3 mt-3">
                  Trusted Matrimony & Matchmaking Service
                </h1>
                {/* <div class="btn-container ">
                  <Link to={"/UserRegister"} className="btn btn-main-2 btn-icon btn-round-full">
                    Register<i class="fa-solid fa-angle-right"></i>
                  </Link>
                </div> */}

                <div className="btn-container ">
                  <Link to={"/UserRegister"} className="btn btn-main btn-round-full">
                    Register<i class="fa-solid fa-angle-right"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="features">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="feature-block d-lg-flex">
                <div class="feature-item mb-5 mb-lg-0">
                  <div class="feature-icon mb-4">
                    <i class="fa-solid fa-user"></i>
                  </div>
                  <span>Why matriomonial ?</span>
                  <h4 class="mb-3">100% Validated Profiles</h4>
                </div>

                <div class="feature-item mb-5 mb-lg-0">
                  <div class="feature-icon mb-4">
                    <i class="fa-solid fa-lock"></i>
                  </div>
                  <span>Why matriomonial ?</span>
                  <h4 class="mb-3">Privacy Maintained</h4>
                </div>

                <div class="feature-item mb-5 mb-lg-0">
                  <div class="feature-icon mb-4">
                    <i class="fa-solid fa-check"></i>
                  </div>
                  <span>Why matriomonial ?</span>
                  <h4 class="mb-3">Control on your account</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="section about">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-4 col-sm-6">
              <div class="about-img ">
                <img src={Image2} alt="" class="img-fluid" />

                <img src={Image3} alt="" class="img-fluid mt-5" />
              </div>
            </div>
            <div class="col-lg-4 col-sm-6">
              <div class="about-img mt-5 mt-lg-0 ">
                <img src={Image4} alt="" class="img-fluid" />
              </div>
            </div>
            <div class="col-lg-4">
              <div class="about-content pl-4 mt-5 mt-lg-0">
                <h2 class="title-color">Matrimonial App</h2>
                <p class="mt-4 mb-4">
                  Visit google playstore, download our app and unlock more
                  features.
                </p>
                <a className="btn btn-main-2 btn-round-full  download">
                  Download<i className="fa-solid fa-angle-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="section testimonial  mt-4">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6 testimonial-wrap offset-lg-6">
              <div class="section-title">
                <h2 class="mb-4 ">Success Stories</h2>
              </div>
              <div class="divider  my-4"></div>
              {loading ? <Skeletonloader /> : <OwlCarousel
                className="owl-carousel owl-theme"
                responsive={{
                  0: {
                    items: 1,
                  },
                  750: {
                    items: 1,
                  },
                  1000: {
                    items: 1,
                  },
                }}
              >
                {datas.map((data) => (
                  <>
                    <div className="item ">
                      <div class="testimonial-block"></div>
                      <div class="client-info ">
                        <h4>{data.title}</h4>
                      </div>
                      <p>{parse(data.description ? data.description : '')}</p>
                      <i class="icofont-quote-right"></i>
                    </div>
                  </>
                )
                )
                }

              </OwlCarousel>}

            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
