import React from 'react'
import Header from '../components/Header/Header'
import Changepassword from '../components/ChangePassword/Changepassword'
import Footer from '../components/Footer/Footer'
const ChangepasswordPage = () => {
  return (
    <>
    <Header/>
    <Changepassword/>
    <Footer/>
    </>
  )
}

export default ChangepasswordPage