import React from 'react'
import Footer from '../components/Footer/Footer'
import Header from '../components/Header/Header'
import ProfileDetail from '../components/ProfileDetail/ProfileDetail'

const ProfileDetailPage = () => {
  return (
   <>
   <Header/>
   <ProfileDetail/>
   <Footer/>
   </>
  )
}

export default ProfileDetailPage