import React, { useState, useEffect } from "react";
import { DisclaimerAPI } from "./DisclaimerAPI";
import Skeletonloader from "../skeletonloader/skeletonloader";
import parse from 'html-react-parser';

const Disclaimer = () => {

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    DisclaimerAPI.get()
      .then((res) => {
        console.log(res.data.data.disclaimer)
        let response = res.data.data.disclaimer;
        setData(response)
        setLoading(false);
      }
      ).catch((err) => {
        console.log(err, "error message")
        setLoading(false);
      })
  },[])

  return (
    <>
      <section className="confirmation mt-5 mb-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="confirmation-content">
                <i className="icofont-check-circled text-lg text-color-2" />
                <h2 className="mt-3 mb-4">Disclaimer</h2>
                {loading ? (
                  <>
                    <Skeletonloader />
                  </>
                ) : (
                  <p className="text-justify">{parse(data.description ? data.description : '')}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Disclaimer;
