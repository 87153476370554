import React, { useState, useEffect, useContext } from "react";
import "./Userprofile.css"
import { UserprofileAPI } from "./UseprofileAPI";
import { Link } from "react-router-dom";
import Userprofileloader from "./Userprofileloader";
import { UserContext } from "../Context/UserContext";
import Sidebar from "./Sidebar";
import axios from 'axios';
import { CanSeeProfileContext } from "../Context/CanSeeProfileContext";

const Userprofile = () => {

  const [profile, setProfile] = useState([])
  const [loading, setLoading] = useState(false);
  // const [subscribedpackage, setSubscribedpackage] = useState(null);
  const [canSeeProfile, setCanSeeProfile] = useState(false);
  const [user, setUser, token] = useContext(UserContext);

  const [subscribedpackage, setSubscribedpackage] = useContext(CanSeeProfileContext);

  const [state, setState] = React.useState({
    gothra: "",
    caste: "",
    religion: '',
    education: '',
    employment_type: '',
    country: '',
    location: ''
  })

  console.log("From component", subscribedpackage)

  useEffect(() => {
    if (subscribedpackage == null) {
      setCanSeeProfile(false)
    }
    else {
      if (subscribedpackage.type == 'profile' && subscribedpackage.no_of_profile_viewed < subscribedpackage.number) {
        setCanSeeProfile(true)
      }
    }
  }, [subscribedpackage])

  // useEffect(() => {
  //   setLoading(true);
  //   UserprofileAPI.get(token)
  //     .then((res) => {
  //       let response = res.data.data.data;
  //       console.log(response)
  //       setProfile(response)
  //       setLoading(false);
  //     }
  //     ).catch((err) => {
  //       console.log(err, "error message")
  //       setLoading(true);
  //     })

  //     UserprofileAPI.subscribepackage()
  //     .then((res) => {
  //       setSubscribedpackage(res.data.data.subscribed_package)
  //     })
  // }, [])

  useEffect(() => {
    UserprofileAPI.subscribepackage()
      .then((res) => {
        setSubscribedpackage(res.data.data.subscribed_package)
      }).catch(e => console.log(e.message))
  }, []);


  useEffect(() => {
    console.log(state)
    setLoading(true);
    let token = localStorage.getItem('token')
    axios({
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/filter-data?gothra=${state.gothra}&caste=${state.caste}&religion=${state.religion}&education=${state.education}&employment_type=${state.employment_type}&country=${state.country}&location=${state.location}`,
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(res => {
      setProfile(res.data.data)
      setLoading(false);
    })
  }, [state])




  const handleChange = (e) => {
    const value = e.target.value;
    console.log('changed')
    setState({
      ...state,
      [e.target.name]: value
    });
  }

  console.log(canSeeProfile)


  return (
    <>
      <div>
        <section className="page-title bg-1 mt-3">
          <div className="overlay" />
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="block text-center">
                  <span className="text-white">View</span>
                  <h1 className="text-capitalize mb-5 text-lg">
                    User's Profiles
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* portfolio */}
        <section className="section-4 doctors mb-3 abcd">
          <div className=" ">

            <Sidebar handleChange={handleChange}>

              <div className="row shuffle-wrapper portfolio-gallery">

                {loading ? <> <Userprofileloader /> </> : <>

                  {canSeeProfile?
                    <>
                      {profile.map((elem) => {
                        return (
                          <>
                            <div className="col-lg-3 col-sm-6 col-md-6 mb-4 shuffle-item">
                              <div className="position-relative doctor-inner-box">
                                <div className="doctor-profile">
                                  <div className="doctor-img">
                                    <img
                                      src={elem.image}
                                      alt="doctor-image"
                                      className="img-fluid w-100"
                                    />
                                  </div>
                                </div>
                                <div className="content mt-3">
                                  <h4 className="mb-0">
                                    <Link to={`/ProfileDetail/${elem.id}`}>{elem.name ? elem.name : ''}</Link>
                                  </h4>
                                  <p>{elem.employment_type}</p>
                                </div>
                              </div>
                            </div>
                          </>
                        )
                      })
                      }
                    </> : <p>Your have not bought any package</p>
                  }
                </>
                }

              </div>
            </Sidebar>
          </div>

        </section>

        {/* /portfolio */}
      </div>
    </>
  );
};

export default Userprofile;
