import React, { useState, useEffect, useContext } from 'react'
import Image14 from '../../assets/images/1.jpg'
import { Link } from 'react-router-dom'
import { UserprofileAPI } from '../Userprofile/UseprofileAPI'
import { UserContext } from "../Context/UserContext";
import { useParams, useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { UserRegisterAPI } from '../UserRegister/UserRegisterAPI';


const ProfileDetail = () => {
  const [id, setId] = useState(null);
  const [loading, setLoading] = useState(false)
  const [profile, setProfile] = useState(null);
  const [user, setUser, token] = useContext(UserContext);
  const [viewedprofile, setViewedProfile] = useState(null);
  const [checklike, setChecklike] = useState(false);
  const [subscribedpackage, setSubscribedpackage] = useState(null);
  const [canSeeProfile, setCanSeeProfile] = useState(false);

  const navigate = useNavigate();

  const params = useParams();


  useEffect(() => {
    UserprofileAPI.checklike(params.id)
      .then((res) => {
        console.log(res.data.data)
        setChecklike(res.data.data)
      }).catch((err) => {
        console.log(err, 'error message')
      })
  }, [checklike])

  useEffect(() => {
    UserprofileAPI.getdetail(params.id)
      .then((res) => {
        console.log(res.data.data)
        setProfile(res.data.data)
      }).catch((err) => {
        console.log(err, 'error message')
      })
  }, [viewedprofile])

  useEffect(() => {
    UserprofileAPI.getprofileview()
      .then((res) => {
        console.log(res.data.data.viewed_profiles)
        setViewedProfile(res.data.data.viewed_profiles)
      }).catch((err) => {
        console.log(err, 'error message')
      })
  }, [viewedprofile])

  useEffect(() => {
    if (viewedprofile) {
      var split_str = viewedprofile?.split(",");
      console.log(split_str)
      if (split_str.indexOf(params.id) !== -1) {
        // Original string contains 7
        console.log('already viewed')
      }
      else {
        console.log('chaina')

        UserprofileAPI.viewprofile(params.id)
          .then((res) => {
            if (res.data.status == true) {
              console.log('profile heriyo')
            }
          }
          )
      }
    }
  }, [viewedprofile])




  // useEffect(() => {
  //   UserprofileAPI.subscribepackage()
  //     .then((res) => {
  //       setSubscribedpackage(res.data.data.subscribed_package)
  //     })
  //   if (subscribedpackage == null) {
  //     setCanSeeProfile(false)
  //     navigate('/UserProfile')
  //   }
  //   else {
  //     if (subscribedpackage.type == 'profile' && subscribedpackage.no_of_profile_viewed < subscribedpackage.number) {
  //       setCanSeeProfile(true)
  //       return {}
  //     }
  //   }

  // }, [subscribedpackage])


  const postlike = () => {
    UserprofileAPI.postlike(params.id)
      .then((res) => {
        if (res.data.status == true) {
          setChecklike(true)
        }
      })
  }
  console.log('profile',profile)

  return (
    <>
      <div>
        <section className="page-title bg-1 mt-3">
          <div className="overlay" />
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="block text-center">
                  <span className="text-white">User' Name</span>
                  <h1 className="text-capitalize mb-5 text-lg">{profile?.name}</h1>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className=" doctor-single mt-5 mb-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="doctor-img-block">
                  <img src={`https://matrimonial.honeywella.com/uploads/images/user_profile/${profile?.image}`} alt="" className="img-fluid w-100" />
                </div>
              </div>
              <div className="col-lg-8 col-md-6">
                <div className="doctor-details mt-4 mt-lg-0">
                  <h2 className="text-md">Introducing to myself</h2>
                  <p ><i className="icofont-email mr-1" />{profile?.email} <i className="icofont-phone mr-1 ml-4" />{profile?.phone_no}</p>
                  <div className="divider my-4" />
                  <div className="row">
                    <div className="col-md-3"><p>Country :</p></div>
                    <div className="col-md-9"><p><strong>{profile?.country}</strong></p></div>
                  </div>
                  <div className="row">
                    <div className="col-md-3"><p>City :</p></div>
                    <div className="col-md-9"><p><strong>{profile?.location}</strong></p></div>
                  </div>
                  <div className="row">
                    <div className="col-md-3"><p>Age :</p></div>
                    <div className="col-md-9"><p><strong>{profile?.age}</strong></p></div>
                  </div>
                  <div className="row">
                    <div className="col-md-3"><p>Gender :</p></div>
                    <div className="col-md-9"><p><strong>{profile?.gender}</strong></p></div>
                  </div>
                  <div className="row">
                    <div className="col-md-3"><p>Maritial Status :</p></div>
                    <div className="col-md-9"><p><strong>{profile?.marital_status}</strong></p></div>
                  </div>
                  <div className="row">
                    <div className="col-md-3"><p>Height :</p></div>
                    <div className="col-md-9"><p><strong>{profile?.height}</strong></p></div>
                  </div>
                  <div className="row">
                    <div className="col-md-3"><p>Occupation :</p></div>
                    <div className="col-md-9"><p><strong>{profile?.employment_type}</strong></p></div>
                  </div>
                  <div className="row">
                    <div className="col-md-3"><p>Education :</p></div>
                    <div className="col-md-9"><p><strong>{profile?.education}</strong></p></div>
                  </div>

                  {checklike == true ? <>
                    <div className=''>
                      <button className='btn btn-main btn-round-full mt-3 liked-button'>Already liked <i class="fa-solid fa-check"></i></button>
                    </div></> : <button className="btn btn-main btn-round-full mt-3" onClick={postlike}>Give a Like<i class="fa-solid fa-heart ic-main"></i>
                  </button>
                  }

                </div>
              </div>
            </div>
          </div>
        </section>
        <section className=" doctor-qualification gray-bg">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="section-title mt-5">
                  <h3>About Me</h3>
                  <div className="divider my-4" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <div className="edu-block mb-5">
                  <span className="h6 text-muted">Religion </span>
                  <h4 className="mb-3 title-color">{profile?.religion}</h4>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="edu-block mb-5">
                  <span className="h6 text-muted">Gotra </span>
                  <h4 className="mb-3 title-color">{profile?.gothra}</h4>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="edu-block ">
                  <span className="h6 text-muted">Caste </span>
                  <h4 className="mb-4 title-color">{profile?.caste}</h4>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className=" doctor-skills">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <h3>Short Bio</h3>
                <div className="divider my-4" />
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. In architecto voluptatem alias, aspernatur voluptatibus corporis quisquam? Consequuntur, ad, doloribus, doloremque voluptatem at consectetur natus eum ipsam dolorum iste laudantium tenetur.</p>
              </div>
              <div className="col-lg-4">
                <div className="skill-list">
                  <h5 className="mb-4">Interest</h5>
                  <ul className="list-unstyled department-service">
                    <li><i class="fa-solid fa-check ic-main"></i>{profile?.interest}</li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="sidebar-widget  gray-bg p-4">
                  <h5 className="mb-4">For More Feature</h5>
                  <div className="sidebar-contatct-info mt-4">
                    <p className="mb-0">Go to playstore and download </p>
                    <h3 className="text-color-2">Matrimonial</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default ProfileDetail