import axios from "axios"

export const MyprofileAPI={
    profile:function(data,token){
       return axios({
            method:"POST",
            url:`${process.env.REACT_APP_API_URL}/store-user-profile`,
            headers:{
                Authorization:`Bearer ${token}`
            },
            data:data
        })
    }
}


    