import React, {useState, useEffect, createContext} from "react";

export const UserContext = createContext();

export const UserProvider = (props) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);

  useEffect(() => {

    // const user = localStorage.getItem("user")
    // if (user) {
    //     const newUser = JSON.parse(localStorage.getItem("user"))
    //   setUser(newUser);
    // }

    if (localStorage.getItem("user")) {
        setUser(JSON.parse(localStorage.getItem("user")));
      }

      if (localStorage.getItem("token")) {
        setToken(localStorage.getItem("token")  );
      }


  }, []);



//   useEffect(() => {
//     localStorage.setItem("user", JSON.stringify(user));
//   }, [user]);



  return (
    <UserContext.Provider value={[user, setUser, token, setToken]}>
      {props.children}
    </UserContext.Provider>
  );
}