import React, { useEffect, useState } from "react";
import { SuccessStoriesAPI } from './SuccessStoriesAPI';
import parse from 'html-react-parser';

const SuccessStories = () => {

  const [datas, setDatas] = useState([]);

  useEffect(() => {
    SuccessStoriesAPI.get()
      .then((res) => {
        console.log(res.data.data.testimonials)
        let response = res.data.data.testimonials;
        setDatas(response)
      }
      ).catch((err) => {
        console.log(err, "error message")
      })
  }, [])




  return (
    <>
      <div>
        <section className="page-title bg-1 mt-3">
          <div className="overlay" />
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="block text-center">
                  <span className="text-white">Success Stories</span>
                  <h1 className="text-capitalize mb-5 text-lg">
                    Success Stories
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section-2 testimonial mb-5 mt-5">
          <div className="container">
            {datas.map((data) => (
            <>
                <div className="row">
                  <div className="col-lg-6 offset-lg-6">
                    <div className="section-title">
                      <h2 >{data.title}</h2>
                      <div className="divider  my-4" />
                    </div>
                  </div>
                </div>
                <div className="row align-items-center">
                  <div className="col-lg-6 offset-lg-6">
                    <div className="testimonial-block">
                      <p>{parse(data.description ? data.description : '')}</p>
                    </div>
                  </div>
                </div>
                </>
                )
                )
                }
          </div>
        </section>

        {/* <section className="section-2 testimonial mb-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 offset-lg-6">
                <div className="section-title">
                  <h2 >Hari &amp; Geeta</h2>
                  <div className="divider  my-4" />
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-6 offset-lg-6">
                <div className="testimonial-block">
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. At
                    vel ipsam laudantium voluptatibus nemo, tenetur aliquam
                    debitis assumenda maiores nihil aliquid. Molestiae iure ad
                    facilis recusandae blanditiis quos consequuntur distinctio.
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Provident consequatur saepe et nemo omnis quia molestias
                    officia, illo, eius totam laudantium sint. Corporis aperiam
                    inventore quas repudiandae suscipit sunt fugit.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section> */}

      </div >
    </>
  );
};

export default SuccessStories;
