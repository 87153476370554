import React from "react";
import { Link } from "react-router-dom";
import "./Changepassword.css";
import Banner from "../Banner/banner";
const Changepassword = () => {
  return (
    <>
      <Banner subtitle={"Change password"} title={"Change password"} />
      <div className="container-fluid change mb-5">
        {/* <div>
          <h4>Change Password</h4>
        </div> */}
        <div className="row">
          <div className="offset-lg-3 offset-md-3"></div>

          <div className="col-lg-6 col-md-6">
            <form>
              <div class="form-group ">
                <label for="exampleInputEmail1">Email address</label>

                <input
                  type="email"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Enter email"
                />

                <small id="emailHelp" class="form-text text-muted">
                  We'll never share your email with anyone else.
                </small>
                <Link to={"/Otp"}>
                  <button className="btn-main3 mt-3">Send</button>
                </Link>
              </div>
            </form>
          </div>
          <div className="offset-lg-3 offset-md-3"></div>
        </div>
      </div>
    </>
  );
};

export default Changepassword;
