import React, { useState, useEffect } from "react";
import Banner from "../Banner/banner";
import Image20 from "../../assets/images/download.jpg";
import "./Myprofile.css";
import { MyprofileAPI } from './MyprofileAPI'
import { UserContext } from "../Context/UserContext";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ValidContact, ValidEmail } from "../../helper/Validators";
import { useSnackbar } from 'notistack';
import { FormControl, FormControlLabel, FormLabel, RadioGroup, Radio } from "@mui/material";


const Myprofile = () => {

  const { enqueueSnackbar } = useSnackbar();

  const [user, setUser, token] = useContext(UserContext);

  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [image, setImage] = useState('');
  const [country, setCountry] = useState('');
  const [location, setLocation] = useState('');
  const [dob, setDob] = useState('');
  const [age, setAge] = useState('');
  const [gender, setGender] = useState('');
  const [maritalstatus, setMaritalstatus] = useState('');
  const [height, setHeight] = useState('');
  const [education, setEducation] = useState('');
  const [language, setLanguage] = useState('');
  const [religion, setReligion] = useState('');
  const [gotra, setGotra] = useState('');
  const [caste, setCaste] = useState('');
  const [employment, setEmployment] = useState('');
  const [annualincome, setAnnualincome] = useState('');
  const [interest, setInterest] = useState('');

  const [response, setResponse] = useState({
    success: null,
    error: null,
  });

  const navigate = useNavigate();
  const [validationMessage, setValidationMessage] = useState({
    name: null,
    phone: null,
    email: null,
    image: null,
    country: null,
    location: null,
    dob: null,
    age: null,
    gender: null,
    maritalstatus: null,
    height: null,
    education: null,
    language: null,
    religion: null,
    gotra: null,
    caste: null,
    employment: null,
    annualincome: null,
    interest: null,

  });


  const postProfile = async (e) => {
    e.preventDefault();
    if (name === "") {
      setValidationMessage({
        name: "Name cannot be empty",
        phone: null,
        email: null,
        image: null,
        country: null,
        location: null,
        dob: null,
        age: null,
        gender: null,
        maritalstatus: null,
        height: null,
        education: null,
        language: null,
        religion: null,
        gotra: null,
        caste: null,
        employment: null,
        annualincome: null,
        interest: null,
      });
      return;
    } else if (dob === '') {
      setValidationMessage({
        name: null,
        phone: null,
        email: null,
        image: null,
        country: null,
        location: null,
        dob: 'Date of birth cannot be empty',
        age: null,
        gender: null,
        maritalstatus: null,
        height: null,
        education: null,
        language: null,
        religion: null,
        gotra: null,
        caste: null,
        employment: null,
        annualincome: null,
        interest: null,
      });
      return;
    } else if (phone === "") {
      setValidationMessage({
        name: null,
        phone: "Phone number cannot be empty",
        email: null,
        image: null,
        country: null,
        location: null,
        dob: null,
        age: null,
        gender: null,
        maritalstatus: null,
        height: null,
        education: null,
        language: null,
        religion: null,
        gotra: null,
        caste: null,
        employment: null,
        annualincome: null,
        interest: null,
      });
      return;
    } else if (ValidContact(phone) === false) {
      setValidationMessage({
        name: null,
        dob: null,
        phone: "Invalid contact number",
        email: null,
        image: null,
        age: null,
        country: null,
        location: null,
        gender: null,
        maritalstatus: null,
        height: null,
        education: null,
        language: null,
        religion: null,
        gotra: null,
        caste: null,
        employment: null,
        annualincome: null,
        interest: null,
      });
      return;
    } else if (email === "") {
      setValidationMessage({
        name: null,
        phone: null,
        email: "Email cannot be empty",
        image: null,
        country: null,
        location: null,
        dob: null,
        age: null,
        gender: null,
        maritalstatus: null,
        height: null,
        education: null,
        language: null,
        religion: null,
        gotra: null,
        caste: null,
        employment: null,
        annualincome: null,
        interest: null,
      });
      return;
    } else if (ValidEmail(email) === false) {
      setValidationMessage({
        name: null,
        phone: null,
        email: "Invalid email address",
        image: null,
        country: null,
        location: null,
        dob: null,
        age: null,
        gender: null,
        maritalstatus: null,
        height: null,
        education: null,
        language: null,
        religion: null,
        gotra: null,
        caste: null,
        employment: null,
        annualincome: null,
        interest: null,
      });
      return;
    } else if (image === '') {
      setValidationMessage({
        name: null,
        phone: null,
        email: null,
        image: 'Image cannot be empty',
        country: null,
        location: null,
        dob: null,
        age: null,
        gender: null,
        maritalstatus: null,
        height: null,
        education: null,
        language: null,
        religion: null,
        gotra: null,
        caste: null,
        employment: null,
        annualincome: null,
        interest: null,
      });
      return;
    } else if (age === '') {
      setValidationMessage({
        name: null,
        phone: null,
        email: null,
        image: null,
        country: null,
        location: null,
        dob: null,
        age: 'Age cannot be empty',
        gender: null,
        maritalstatus: null,
        height: null,
        education: null,
        language: null,
        religion: null,
        gotra: null,
        caste: null,
        employment: null,
        annualincome: null,
        interest: null,
      });
      return;
    } else if (gender === '') {
      setValidationMessage({
        name: null,
        phone: null,
        email: null,
        image: null,
        country: null,
        location: null,
        dob: null,
        age: null,
        gender: 'Gender cannot be empty',
        maritalstatus: null,
        height: null,
        education: null,
        language: null,
        religion: null,
        gotra: null,
        caste: null,
        employment: null,
        annualincome: null,
        interest: null,
      });
      return;
    } else if (height === '') {
      setValidationMessage({
        name: null,
        phone: null,
        email: null,
        image: null,
        country: null,
        location: null,
        dob: null,
        age: null,
        gender: null,
        maritalstatus: null,
        height: 'Height cannot be empty',
        education: null,
        language: null,
        religion: null,
        gotra: null,
        caste: null,
        employment: null,
        annualincome: null,
        interest: null,
      });
      return;
    } else if (country === '') {
      setValidationMessage({
        name: null,
        phone: null,
        email: null,
        image: null,
        country: "Country cannot be empty",
        location: null,
        dob: null,
        age: null,
        gender: null,
        maritalstatus: null,
        height: null,
        education: null,
        language: null,
        religion: null,
        gotra: null,
        caste: null,
        employment: null,
        annualincome: null,
        interest: null,
      });
      return;
    } else if (location === '') {
      setValidationMessage({
        name: null,
        phone: null,
        email: null,
        image: null,
        country: null,
        location: "City cannot be empty",
        dob: null,
        age: null,
        gender: null,
        maritalstatus: null,
        height: null,
        education: null,
        language: null,
        religion: null,
        gotra: null,
        caste: null,
        employment: null,
        annualincome: null,
        interest: null,
      });
      return;
    } else if (religion === '') {
      setValidationMessage({
        name: null,
        phone: null,
        email: null,
        image: null,
        country: null,
        location: null,
        dob: null,
        age: null,
        gender: null,
        maritalstatus: null,
        height: null,
        education: null,
        language: null,
        religion: 'Religion cannot be empty',
        gotra: null,
        caste: null,
        employment: null,
        annualincome: null,
        interest: null,
      });
      return;
    } else if (gotra === '') {
      setValidationMessage({
        name: null,
        phone: null,
        email: null,
        image: null,
        country: null,
        location: null,
        dob: null,
        age: null,
        gender: null,
        maritalstatus: null,
        height: null,
        education: null,
        language: null,
        religion: null,
        gotra: "Gotra cannot be empty",
        caste: null,
        employment: null,
        annualincome: null,
        interest: null,
      });
      return;
    } else if (caste === '') {
      setValidationMessage({
        name: null,
        phone: null,
        email: null,
        image: null,
        country: null,
        location: null,
        dob: null,
        age: null,
        gender: null,
        maritalstatus: null,
        height: null,
        education: null,
        language: null,
        religion: null,
        gotra: null,
        caste: "Caste cannot be empty",
        employment: null,
        annualincome: null,
        interest: null,
      });
      return;
    } else if (language === '') {
      setValidationMessage({
        name: null,
        phone: null,
        email: null,
        image: null,
        country: null,
        location: null,
        dob: null,
        age: null,
        gender: null,
        maritalstatus: null,
        height: null,
        education: null,
        language: 'Language cannot be empty',
        religion: null,
        gotra: null,
        caste: null,
        employment: null,
        annualincome: null,
        interest: null,
      });
      return;
    } else if (maritalstatus === '') {
      setValidationMessage({
        name: null,
        phone: null,
        email: null,
        image: null,
        country: null,
        location: null,
        dob: null,
        age: null,
        gender: null,
        maritalstatus: 'Marital status cannot be empty',
        height: null,
        education: null,
        language: null,
        religion: null,
        gotra: null,
        caste: null,
        employment: null,
        annualincome: null,
        interest: null,
      });
      return;
    } else if (education === '') {
      setValidationMessage({
        name: null,
        phone: null,
        email: null,
        image: null,
        country: null,
        location: null,
        dob: null,
        age: null,
        gender: null,
        maritalstatus: null,
        height: null,
        education: 'Education cannot be empty',
        language: null,
        religion: null,
        gotra: null,
        caste: null,
        employment: null,
        annualincome: null,
        interest: null,
      });
      return;
    } else if (employment === '') {
      setValidationMessage({
        name: null,
        phone: null,
        email: null,
        image: null,
        country: null,
        location: null,
        dob: null,
        age: null,
        gender: null,
        maritalstatus: null,
        height: null,
        education: null,
        language: null,
        religion: null,
        gotra: null,
        caste: null,
        employment: 'Employment cannot be empty',
        annualincome: null,
        interest: null,
      });
      return;
    } else if (annualincome === '') {
      setValidationMessage({
        name: null,
        phone: null,
        email: null,
        image: null,
        country: null,
        location: null,
        dob: null,
        age: null,
        gender: null,
        maritalstatus: null,
        height: null,
        education: null,
        language: null,
        religion: null,
        gotra: null,
        caste: null,
        employment: null,
        annualincome: 'Annual Income cannot be empty',
        interest: null,
      });
      return;
    } else if (interest === '') {
      setValidationMessage({
        name: null,
        phone: null,
        email: null,
        image: 'Image cannot be empty',
        country: null,
        location: null,
        dob: null,
        age: null,
        gender: null,
        maritalstatus: null,
        height: null,
        education: null,
        language: null,
        religion: null,
        gotra: null,
        caste: null,
        employment: null,
        annualincome: null,
        interest: 'Interest cannot be empty',
      });
      return;
    }
    else {
      setValidationMessage({
        name: null,
        phone: null,
        email: null,
        image: null,
        country: null,
        location: null,
        dob: null,
        age: null,
        gender: null,
        maritalstatus: null,
        height: null,
        education: null,
        language: null,
        religion: null,
        gotra: null,
        caste: null,
        employment: null,
        annualincome: null,
        interest: null,
      });

      await MyprofileAPI.profile({
        name: name,
        phone: phone,
        email: email,
        image: image,
        country: country,
        location: location,
        dob: dob,
        age: age,
        gender: gender,
        marital_status: maritalstatus,
        height: height,
        education: education,
        language: language,
        religion: religion,
        gothra: gotra,
        caste: caste,
        employment_type: employment,
        annual_income: annualincome,
        interest: interest,
        token: token
      }, token).then(res => {
        console.log('my res', res.data)
        if (res.data.status === true) {
          setName('')
          setPhone('')
          setEmail('')
          setImage('')
          setCountry('')
          setLocation('')
          setDob('')
          setAge('')
          setGender('')
          setMaritalstatus('')
          setHeight('')
          setEducation('')
          setLanguage('')
          setReligion('')
          setGotra('')
          setCaste('')
          setEmployment('')
          setAnnualincome('')
          setInterest('')
          enqueueSnackbar('Your profile has been submitted for verification', { variant: 'success' });
          navigate('/')
        }
        else {
          enqueueSnackbar(res.data.message, { variant: 'error' });
        }
      }).catch(error => {
        enqueueSnackbar('Server Error', { variant: 'error' });
      })
    }

    setTimeout(() => {
    }, 5000)

  }

  return (
    <>
      <Banner subtitle={"My profile"} title={"My profile"} />
      <div className="profile-section">
        <div className="container-fluid mt-5 mb-5">
          <div className="row">
            <div className="col-lg-3">
              <div className="profile">
                <img src={Image20} alt="Image" className="rounded-circle" />
              </div>
              <div className="bio1">
                <span className="poo">Poonam Pant</span>

                <span className="xxx">xxxx@gmail.com</span>
              </div>
            </div>
            <div className="bio"></div>
            <div className="col-lg-5">
              <div className="my">My profile</div>

              <div className="row">
                <div className="col-lg-6 group">
                  <div className="form-group mt-4">
                    <label for="exampleInputPassword1">Full Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputPassword1"
                      placeholder="Full Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      onKeyPress={(event) => { if (!/[A-Z a-z]/.test(event.key)) { event.preventDefault(); } }}
                      style={
                        validationMessage.name
                          ? {
                            border: "1px solid red",
                          }
                          : null
                      }
                    />
                    {validationMessage.name ? (
                      <span className="error error-message">
                        {validationMessage.name}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group mt-4">
                    <label for="start"> Birth Date</label>
                    <input
                      type="date"
                      className="form-control"
                      id="start"
                      placeholder="Birth Date"
                      value={dob}
                      onChange={(e) => setDob(e.target.value)}
                      style={
                        validationMessage.dob
                          ? {
                            border: "1px solid red",
                          }
                          : null
                      }
                    />
                    {validationMessage.dob ? (
                      <span className="error error-message">
                        {validationMessage.dob}
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label for="exampleInputPassword1">Phone</label>
                <input
                  type="tel"
                  maxLength={10}
                  className="form-control"
                  id="exampleInputPassword1"
                  placeholder="Phone Number"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }}
                  style={
                    validationMessage.phone
                      ? {
                        border: "1px solid red",
                      }
                      : null
                  }
                />
                {validationMessage.phone ? (
                  <span className="error error-message">
                    {validationMessage.phone}
                  </span>
                ) : null}
              </div>
              <div className="form-group">
                <label for="exampleInputPassword1">Email</label>
                <input
                  type="email"
                  className="form-control"
                  id="exampleInputPassword1"
                  placeholder="Email Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  style={
                    validationMessage.email
                      ? {
                        border: "1px solid red",
                      }
                      : null
                  }
                />
                {validationMessage.email ? (
                  <span className="error error-message">
                    {validationMessage.email}
                  </span>
                ) : null}
              </div>

              <div className="form-group">
                <label for="exampleInputPassword1">Image</label>
                <input
                  type="file" name="myImage" accept="image/png, image/gif, image/jpeg"
                  className="form-control"
                  id="exampleInputPassword1"
                  value={image}
                  onChange={(e) => setImage(e.target.value)}
                  style={
                    validationMessage.image
                      ? {
                        border: "1px solid red",
                      }
                      : null
                  }
                />
                {validationMessage.image ? (
                  <span className="error error-message">
                    {validationMessage.image}
                  </span>
                ) : null}
              </div>

              <div class="form-group">
                <label for="exampleInputEmail1">Age</label>
                <input
                  type="text"
                  class="form-control"
                  id="exampleInputEmail1"
                  placeholder="Age"
                  value={age}
                  onChange={(e) => setAge(e.target.value)}
                  onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }}
                  style={
                    validationMessage.age
                      ? {
                        border: "1px solid red",
                      }
                      : null
                  }
                />
                {validationMessage.age ? (
                  <span className="error error-message">
                    {validationMessage.age}
                  </span>
                ) : null}

              </div>

              <div class="form-group">
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={gender}
                    onChange={(e) => setGender(e.target.value)}
                  >
                    <FormControlLabel value="female" control={<Radio />} label="Female" />
                    <FormControlLabel value="male" control={<Radio />} label="Male" />
                    <FormControlLabel value="other" control={<Radio />} label="Other" />
                  </RadioGroup>

                  {validationMessage.gender ? (
                    <span className="error error-message">
                      {validationMessage.gender}
                    </span>
                  ) : null}
                </FormControl>

              </div>

              <div class="form-group">
                <label for="exampleInputEmail1">Height</label>
                <input
                  class="form-control"
                  id="exampleInputEmail1"
                  placeholder="Height"
                  value={height}
                  onChange={(e) => setHeight(e.target.value)}
                  style={
                    validationMessage.height
                      ? {
                        border: "1px solid red",
                      }
                      : null
                  }
                />
                {validationMessage.height ? (
                  <span className="error error-message">
                    {validationMessage.height}
                  </span>
                ) : null}
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">Country</label>
                <input
                  type="text"
                  class="form-control"
                  id="exampleInputEmail1"
                  placeholder="Country"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                  onKeyPress={(event) => { if (!/[A-Z a-z]/.test(event.key)) { event.preventDefault(); } }}
                  style={
                    validationMessage.country
                      ? {
                        border: "1px solid red",
                      }
                      : null
                  }
                />
                {validationMessage.country ? (
                  <span className="error error-message">
                    {validationMessage.country}
                  </span>
                ) : null}
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">City</label>
                <input
                  type="text"
                  class="form-control"
                  id="exampleInputEmail1"
                  placeholder="City"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  onKeyPress={(event) => { if (!/[A-Z a-z]/.test(event.key)) { event.preventDefault(); } }}
                  style={
                    validationMessage.location
                      ? {
                        border: "1px solid red",
                      }
                      : null
                  }
                />
                {validationMessage.location ? (
                  <span className="error error-message">
                    {validationMessage.location}
                  </span>
                ) : null}
              </div>

              <div className="save mt-5 mb-4">
                <button className="btn btn-main" type="submit" onClick={postProfile}>
                  Submit for Verification
                </button>
              </div>

            </div>

            <div className="bio3"></div>
            <div className="col-lg-3 info">
              <div className="personal">Personal information</div>
              <div class="form-group mt-4">
                <label for="exampleInputEmail1">Religion</label>
                <input
                  type="text"
                  class="form-control"
                  id="exampleInputEmail1"
                  placeholder="Religion"
                  value={religion}
                  onChange={(e) => setReligion(e.target.value)}
                  onKeyPress={(event) => { if (!/[A-Z a-z]/.test(event.key)) { event.preventDefault(); } }}
                  style={
                    validationMessage.religion
                      ? {
                        border: "1px solid red",
                      }
                      : null
                  }
                />
                {validationMessage.religion ? (
                  <span className="error error-message">
                    {validationMessage.religion}
                  </span>
                ) : null}
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">Gotra</label>
                <input
                  type="text"
                  class="form-control"
                  id="exampleInputEmail1"
                  placeholder="Gotra"
                  value={gotra}
                  onChange={(e) => setGotra(e.target.value)}
                  onKeyPress={(event) => { if (!/[A-Z a-z]/.test(event.key)) { event.preventDefault(); } }}
                  style={
                    validationMessage.gotra
                      ? {
                        border: "1px solid red",
                      }
                      : null
                  }
                />
                {validationMessage.gotra ? (
                  <span className="error error-message">
                    {validationMessage.gotra}
                  </span>
                ) : null}
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">Caste</label>
                <input
                  type="text"
                  class="form-control"
                  id="exampleInputEmail1"
                  placeholder="Caste"
                  value={caste}
                  onChange={(e) => setCaste(e.target.value)}
                  onKeyPress={(event) => { if (!/[A-Z a-z]/.test(event.key)) { event.preventDefault(); } }}
                  style={
                    validationMessage.caste
                      ? {
                        border: "1px solid red",
                      }
                      : null
                  }
                />
                {validationMessage.caste ? (
                  <span className="error error-message">
                    {validationMessage.caste}
                  </span>
                ) : null}
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">Language</label>
                <input
                  type="text"
                  class="form-control"
                  id="exampleInputEmail1"
                  placeholder="Language"
                  value={language}
                  onChange={(e) => setLanguage(e.target.value)}
                  onKeyPress={(event) => { if (!/[A-Z a-z]/.test(event.key)) { event.preventDefault(); } }}
                  style={
                    validationMessage.language
                      ? {
                        border: "1px solid red",
                      }
                      : null
                  }
                />
                {validationMessage.language ? (
                  <span className="error error-message">
                    {validationMessage.language}
                  </span>
                ) : null}
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">Marital Status</label>
                <input
                  type="text"
                  class="form-control"
                  id="exampleInputEmail1"
                  placeholder="Marital Status"
                  value={maritalstatus}
                  onChange={(e) => setMaritalstatus(e.target.value)}
                  onKeyPress={(event) => { if (!/[A-Z a-z]/.test(event.key)) { event.preventDefault(); } }}
                  style={
                    validationMessage.maritalstatus
                      ? {
                        border: "1px solid red",
                      }
                      : null
                  }
                />
                {validationMessage.maritalstatus ? (
                  <span className="error error-message">
                    {validationMessage.maritalstatus}
                  </span>
                ) : null}
              </div>

              <div class="form-group">
                <label for="exampleInputEmail1">Education</label>
                <input
                  type="text"
                  class="form-control"
                  id="exampleInputEmail1"
                  placeholder="Education"
                  value={education}
                  onChange={(e) => setEducation(e.target.value)}
                  onKeyPress={(event) => { if (!/[A-Z a-z]/.test(event.key)) { event.preventDefault(); } }}
                  style={
                    validationMessage.education
                      ? {
                        border: "1px solid red",
                      }
                      : null
                  }
                />
                {validationMessage.education ? (
                  <span className="error error-message">
                    {validationMessage.education}
                  </span>
                ) : null}
              </div>

              <div class="form-group">
                <label for="exampleInputEmail1">Employment Type</label>
                <input
                  type="text"
                  class="form-control"
                  id="exampleInputEmail1"
                  placeholder="Employment Type"
                  value={employment}
                  onChange={(e) => setEmployment(e.target.value)}
                  onKeyPress={(event) => { if (!/[A-Z a-z]/.test(event.key)) { event.preventDefault(); } }}
                  style={
                    validationMessage.employment
                      ? {
                        border: "1px solid red",
                      }
                      : null
                  }
                />
                {validationMessage.employment ? (
                  <span className="error error-message">
                    {validationMessage.employment}
                  </span>
                ) : null}
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">Annual Income</label>
                <input
                  type="text"
                  class="form-control"
                  id="exampleInputEmail1"
                  placeholder="Annual Income"
                  value={annualincome}
                  onChange={(e) => setAnnualincome(e.target.value)}
                  style={
                    validationMessage.annualincome
                      ? {
                        border: "1px solid red",
                      }
                      : null
                  }
                />
                {validationMessage.annualincome ? (
                  <span className="error error-message">
                    {validationMessage.annualincome}
                  </span>
                ) : null}
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">Interest</label>
                <input
                  type="text"
                  class="form-control"
                  id="exampleInputEmail1"
                  placeholder="Interest"
                  value={interest}
                  onChange={(e) => setInterest(e.target.value)}
                  onKeyPress={(event) => { if (!/[A-Z a-z]/.test(event.key)) { event.preventDefault(); } }}
                  style={
                    validationMessage.interest
                      ? {
                        border: "1px solid red",
                      }
                      : null
                  }
                />
                {validationMessage.interest ? (
                  <span className="error error-message">
                    {validationMessage.interest}
                  </span>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Myprofile;
