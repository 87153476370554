import axios from "axios"

export const UserRegisterAPI={
    register:function(data) {
      return axios({
            method: "POST",
            url: `${process.env.REACT_APP_API_URL}/user/request-register`,
            data: data
        })
    }
}



