import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Header.css";
import Image1 from "../../assets/images/logo.png";
import { Link } from "react-router-dom";
import { HeaderAPI } from "./HeaderAPI";
import { useSnackbar } from 'notistack';
import { LoadingButton } from "@mui/lab";
import { UserContext } from "../Context/UserContext";
import { ValidContact, ValidEmail } from "../../helper/Validators";
import { useContext } from "react";
import { CanSeeProfileContext } from "../Context/CanSeeProfileContext";


const Header = () => {

  const [user, setUser, token, setToken] = useContext(UserContext);
  const[subscribedpackage,setSubscribedpackage]=useContext(CanSeeProfileContext)
  const [phone, setPhone] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const [validationMessage, setValidationMessage] = useState({
    phone: null,
    password: null,
  });

  const [response, setResponse] = useState({
    success: null,
    error: null,
  });

  const logout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    setUser(null);
    setSubscribedpackage(null)
    navigate('/')
    enqueueSnackbar('Logout Successfully', { variant: 'success' });
  }

  const postLogin = async (e) => {
    e.preventDefault();
    setLoading(true)
    if (phone === '') {
      setValidationMessage({
        phone: "Phone number cannot be empty",
        password: null,
      });
      return;
    } else if (ValidContact(phone) === false) {
      setValidationMessage({
        phone: "Invalid contact number",
        password: null,
      });
      return;
    } else if (password === '') {
      setValidationMessage({
        phone: null,
        password: "Password cannot be empty",
      });
      return
    }
    else {
      setValidationMessage({
        phone: null,
        password: null,
      });
      setLoading(true)
      await HeaderAPI.login({
        phone_no: phone,
        password: password
      }).then(res => {
        if (res.data.status === true) {
          setUser(res.data.data.user)
          setToken(res.data.data.access_token)
          localStorage.setItem("user", JSON.stringify(res.data.data.user));
          localStorage.setItem("token", res.data.data.access_token);
          setPhone('')
          setPassword('')
          enqueueSnackbar('Login Successfully', { variant: 'success' });
          navigate('/')
        }
        else {
          enqueueSnackbar(res.data.message, { variant: 'error' });
        }
      }).catch(error => {
        enqueueSnackbar('Server Error', { variant: 'error' });
      })
      setLoading(false)
    }
    setTimeout(() => {
    }, 5000)
  }


  return (
    <>

      <header>
        <nav className="navbar navbar-expand-lg navigation" id="navbar">
          <div className="container">
            <a className="navbar-brand">
              <Link to={"/"}>
                <img src={Image1} alt="" class="img-fluid" />
              </Link>
            </a>
            <button
              className="navbar-toggler collapsed"
              type="button"
              data-toggle="collapse"
              data-target="#navbarmain"
              aria-controls="navbarmain"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="icofont-navigation-menu">
                <i class="fa-solid fa-bars"></i>
              </span>
            </button>
            <div
              className="collapse navbar-collapse header-login-card"
              id="navbarmain"
            >
              <form id="#" className="form-inline header-login-form" action="#">

                {user === null ? (<>

                  <input
                    name="phone"
                    maxLength={10}
                    minLength={10}
                    id="phone"
                    type="tel"
                    className="form-control ml-2"
                    placeholder="Phone Number"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }}
                    style={
                      validationMessage.phone
                        ? {
                          border: "1px solid red",
                        }
                        : null
                    }
                    
                  />
                 

                  <div>
                    <input
                      name="password"
                      id="password"
                      type="password"
                      className="form-control ml-2 "
                      placeholder="Type Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      style={
                        validationMessage.password
                          ? {
                            border: "1px solid red",
                          }
                          : null
                      }
                    />
                  </div>
                  {validationMessage.password ? (
                    <span className="error error-message">
                      {validationMessage.password}
                    </span>
                  ) : null}

                  <Link to={"/Userprofile"}>
                    <LoadingButton className="btn btn-main ml-2" type="submit" loading={loading} onClick={postLogin}>
                      Let's get Started
                      <i class="fa-solid fa-angle-right ic-main"></i>
                    </LoadingButton>
                  </Link>

                  <Link to={"/Changepassword"}>
                    <div className="forgot">
                      <button className="forgot-btn">Forgot a password?</button>
                    </div>
                  </Link>

                </>) : (

                  <>
                    <Link to={"/Userprofile"}>
                      <div className="profile-link">
                        View Profile
                      </div>
                    </Link>
                    <Link to={"/Myprofile"}>
                      <div className="profile-link ml-4">
                        My Profile
                        <i class="fa-solid fa-angle-right ic-main"></i>
                      </div>
                    </Link>

                    <LoadingButton className="btn btn-main ml-4" onClick={() => logout()}>
                      Logout
                      <i className="fa-solid fa-arrow-right-from-bracket"></i>
                    </LoadingButton>
                  </>
                )}

              </form>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;
