import React, { useEffect, useState } from 'react'
import Image5 from '../../assets/images/about-1.jpg'
import Image6 from '../../assets/images/about-2.jpg'
import Image7 from '../../assets/images/about-3.jpg'
import Image8 from '../../assets/images/1.png'
import Image9 from '../../assets/images/2.png'
import Image10 from '../../assets/images/3.png'
import Image11 from '../../assets/images/4.png'
import Image12 from '../../assets/images/5.png'
import Image13 from '../../assets/images/6.png'
import Image14 from '../../assets/images/1.jpg'
import Image15 from '../../assets/images/2.jpg'
import Image16 from '../../assets/images/3.jpg'
import Image17 from '../../assets/images/4.jpg'
import "./About.css"
import { AboutAPI } from './AboutAPI';
import parse from 'html-react-parser';
import Skeletonloader from '../skeletonloader/skeletonloader'

const About = () => {

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    AboutAPI.get()
      .then((res) => {
        console.log(res.data.data.about)
        let response = res.data.data.about;
        setData(response)
        setLoading(false);
      }
      ).catch((err) => {
        console.log(err, 'error message')
        setLoading(false);
      })
  }, [setData])


  return (
    <>
      <section class="page-title bg-1 mt-3">
        <div class="overlay"></div>
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="block text-center">
                <span class="text-white">About Us</span>
                <h1 class="text-capitalize mb-5 text-lg">About Us</h1>

              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="section-1 about-page ">
        <div class="container">
          <div class="row">
            <div class="col-lg-4">
              <h2 class="title-color">Trusted Matrimony & Matchmaking Service</h2>
            </div>
            <div class="col-lg-8">
              {loading? (
              <>
              <Skeletonloader/>
              </>
              ):(
                <p className="text">{parse(data.description ? data.description : '')}</p>
              )
              }
              
            </div>


          </div>
        </div>
      </section>

      <section class="fetaure-page mt-5 ">
        <div class="container">
          <div class="row">
            <div class="col-lg-4 col-md-4">
              <div class="about-block-item mb-5 mb-lg-0">
                <img src={Image5} alt="" class="img-fluid w-100" />
                <h4 class="mt-3">100% Validated Profiles</h4>
                <p className="text">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Error pariatur fugiat labore laborum repudiandae, veniam nulla, doloribus amet necessitatibus provident eos excepturi odit optio recusandae vero illum iure natus dolorum.</p>
              </div>
            </div>
            <div class="col-lg-4 col-md-4">
              <div class="about-block-item mb-5 mb-lg-0">
                <img src={Image6} alt="" class="img-fluid w-100" />
                <h4 class="mt-3">Privacy Maintained</h4>
                <p className="text">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Repellendus sit perferendis qui tempore dolor ipsam optio, eaque amet voluptatibus! Nulla voluptatem repellat voluptas itaque ad ipsam accusamus explicabo.</p>
              </div>
            </div>
            <div class="col-lg-4 col-md-4">
              <div class="about-block-item mb-5 mb-lg-0">
                <img src={Image7} alt="" class="img-fluid w-100" />
                <h4 class="mt-3">Control on your account</h4>
                <p className="text">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ex esse illum at minus? Repellat accusantium eum quasi. Omnis autem ad beatae in deserunt doloribus, voluptatibus vero velit? Ipsum, iure sed!</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class=" awards ">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-4">
              <h2 class="title-color">Our achievements </h2>
              <div class="divider mt-4 mb-5 mb-lg-0"></div>
            </div>
            <div class="col-lg-8">
              <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6">
                  <div class="award-img">
                    <img src={Image8} alt="" class="img-fluid" />
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                  <div class="award-img">
                    <img src={Image9} alt="" class="img-fluid" />
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                  <div class="award-img">
                    <img src={Image10} alt="" class="img-fluid" />
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                  <div class="award-img">
                    <img src={Image11} alt="" class="img-fluid" />
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                  <div class="award-img">
                    <img src={Image12} alt="" class="img-fluid" />
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                  <div class="award-img">
                    <img src={Image13} alt="" class="img-fluid" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="team mt-5">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-6">
              <div class="section-title text-center">
                <h2 class="mb-4">Meet Our Team</h2>
                <div class="divider1 "></div>
              </div>
            </div>
          </div>


          <div className="row shuffle-wrapper portfolio-gallery mb-5">
            <div className="col-lg-3 col-sm-6 col-md-6 shuffle-item">
              <div className="position-relative doctor-inner-box">
                <div className="doctor-profile">
                  <div className="doctor-img">
                    <img
                      src={Image14}
                      alt="doctor-image"
                      className="img-fluid w-100"
                    />
                  </div>
                </div>
                <div class="content">
                  <h4 class="mt-4 mb-0">Full Name</h4>
                  <p>Designation goes here</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-md-6  shuffle-item">
              <div className="position-relative doctor-inner-box">
                <div className="doctor-profile">
                  <div className="doctor-img">
                    <img
                      src={Image15}
                      alt="doctor-image"
                      className="img-fluid w-100"
                    />
                  </div>
                </div>
                <div class="content">
                  <h4 class="mt-4 mb-0">Full Name</h4>
                  <p>Designation goes here</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-md-6  shuffle-item">
              <div className="position-relative doctor-inner-box">
                <div className="doctor-profile">
                  <div className="doctor-img">
                    <img
                      src={Image16}
                      alt="doctor-image"
                      className="img-fluid w-100"
                    />
                  </div>
                </div>
                <div class="content">
                  <h4 class="mt-4 mb-0">Full Name</h4>
                  <p>Designation goes here</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-md-6 shuffle-item">
              <div className="position-relative doctor-inner-box">
                <div className="doctor-profile">
                  <div className="doctor-img">
                    <img
                      src={Image17}
                      alt="doctor-image"
                      className="img-fluid w-100"
                    />
                  </div>
                </div>
                <div class="content">
                  <h4 class="mt-4 mb-0">Full Name</h4>
                  <p>Designation goes here</p>
                </div>
              </div>
            </div>
          </div>
          {/* <div class="col-lg-3 col-md-6 col-sm-6">
                  <div class="team-block mb-5 mb-lg-0">
                      <div class="team-profile">
                        <img src={Image14} alt="" class="img-fluid w-100"/>
                      </div>
  
                      <div class="content">
                        <h4 class="mt-4 mb-0">Full Name</h4>
                          <p>Designation goes here</p>
                      </div>
                  </div>
              </div> */}
          {/*   
              <div class="col-lg-3 col-md-6 col-sm-6">
                  <div class="team-block mb-5 mb-lg-0">
                    <div class="team-profile">
                        <img src={Image15} alt="" class="img-fluid w-100"/>
                      </div>
  
                      <div class="content">
                        <h4 class="mt-4 mb-0">Full Name</h4>
                          <p>Designation goes here</p>
                      </div>
                  </div>
              </div>
  
              <div class="col-lg-3 col-md-6 col-sm-6">
                  <div class="team-block mb-5 mb-lg-0">
                    <div class="team-profile">
                        <img src={Image16} alt="" class="img-fluid w-100"/>
                      </div>
  
                      <div class="content">
                        <h4 class="mt-4 mb-0">Full Name</h4>
                          <p>Designation goes here</p>
                      </div>
                  </div>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-6">
                  <div class="team-block">
                    <div class="team-profile">
                        <img src={Image17} alt="" class="img-fluid w-100"/>
                      </div>
  
                      <div class="content">
                          <h4 class="mt-4 mb-0">Full Name</h4>
                          <p>Designation goes here</p>
                      </div>
                  </div>
              </div> */}

        </div>
      </section>


    </>
  )
}

export default About