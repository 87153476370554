import React from 'react'
import Header from '../components/Header/Header'
import Home from '../components/Homedetails/Home'
import Footer from '../components/Footer/Footer'
import Snackbar from '../components/snackbar/snackbar'


const HomePage = () => {

  return (
    <>
    <Header/>
    <Home/>
    <Footer/>
    <Snackbar/>
    <Snackbar/>
    </>
  )

}

export default HomePage