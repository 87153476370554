import React from 'react'
import About from '../components/About/About'
import Footer from '../components/Footer/Footer'
import Header from '../components/Header/Header'

const AboutPage = () => {
  return (
    <>
    <Header/>
    <About/>
    <Footer/>

    </>
  )
}

export default AboutPage