import React, { useState, useEffect } from "react";
import "./Footer.css";
import Image1 from "../../assets/images/logo.png";
import { Link } from "react-router-dom";
import { FooterAPI } from './FooterAPI';
import StickyBox from "react-sticky-box";
import { Skeleton } from "@mui/material";

const Footer = () => {

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    FooterAPI.get()
      .then((res) => {
        let response = res.data.data.setting;
        setData(response)
        setLoading(false);
      }
      ).catch((err) => {
        console.log(err, "error message")
        setLoading(false);
      })
  }, [])

  return (
    <>
      <footer class="footer  gray-bg ">
        <div class="container">
          <div class="row ">
            <div class="col-lg-4 mr-auto col-sm-6">
              <div class="widget mb-5 mb-lg-0 temp1">
                <div class="logo mb-4">
                  <img src={Image1} alt="" class="img-fluid" />
                </div>
                <p>
                  Tempora dolorem voluptatum nam vero assumenda voluptate,
                  facilis ad eos obcaecati tenetur veritatis eveniet distinctio
                  possimus.
                </p>

                <ul class="list-inline footer-socials mt-4">
                  <li class="list-inline-item">
                    <a href={data.facebook_link} target="_blank">
                      <i class="fa-brands fa-facebook-f fb"></i>
                    </a>
                  </li>
                  <li class="list-inline-item">
                    <a href={data.twitter_link} target="_blank">
                      <i class="fa-brands fa-twitter tw"></i>
                    </a>
                  </li>
                  <li class="list-inline-item">
                    <a href={data.linkedin_link} target="_blank">
                      <i class="fa-brands fa-linkedin-in link"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-lg-2 col-md-6 col-sm-6">
              <div class="widget mb-5 mb-lg-0 temp">
                <h4 class="text-capitalize mb-3">Links</h4>
                <div class="divider mb-4"></div>

                <ul class="list-unstyled footer-menu lh-35">
                  <Link to={"/about"}>
                    <li>About</li>
                  </Link>

                  <Link to={"/SuccessStories"}>
                    <li>Succes Stories</li>
                  </Link>
                  <Link to={"/Contact"}>
                    <li>Contact</li>
                  </Link>
                  <Link to={"/Package"}>
                    <li>Packages</li>
                  </Link>
                </ul>
              </div>
            </div>

            <div class="col-lg-2 col-md-6 col-sm-6">
              <div class="widget mb-5 mb-lg-0 temp">
                <h4 class="text-capitalize mb-3">Support</h4>
                <div class="divider mb-4"></div>

                <ul class="list-unstyled footer-menu lh-35">
                  <Link to={"/TermsandConditions"}>
                    <li>Terms & Conditions</li>
                  </Link>
                  <Link to={"/PrivacyPolicy"}>
                    <li>Privacy Policy</li>
                  </Link>
                  <Link to={"/Disclaimer"}>
                    <li>Disclaimer</li>
                  </Link>
                </ul>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
              <div class="widget widget-contact mb-5 mb-lg-0 temp">
                <h4 class="text-capitalize mb-3">Get in Touch</h4>
                <div class="divider mb-4"></div>

                <div class="footer-contact-block mb-4">
                  <div class="icon d-flex align-items-center">
                    <i class="fa-solid fa-envelope ic-main"></i>
                    <span class="h6 mb-0">Support Available for 24/7</span>
                  </div>
                  <h4 class="mt-2">
                    <a href={data.email1}>
                      {loading ? <Skeleton /> : <> {data.email1}</>}
                    </a>
                  </h4>
                </div>

                <div class="footer-contact-block">
                  <div class="icon d-flex align-items-center">
                    <i class="fa-solid fa-headset ic-main"></i>
                    <span class="h6 mb-0">Mon to Fri : 08:30 - 18:00</span>
                  </div>
                  <h4 class="mt-2">
                    <a href={data.contact1}>
                      {loading ? <Skeleton /> : <>{data.contact1}</>}
                    </a>
                  </h4>
                </div>
              </div>
            </div>
          </div>

          <div class="footer-btm py-2 mt-4">
            <div class="row align-items-center justify-content-between">
              <div class="col-lg-6">
                <div class="copyright mt-4">
                  &copy; Copyright Reserved to{" "}
                  <span class="text-color">Matrimonial</span> Developed by{" "}
                  <a href="" target="_blank">
                    Creatu Developers
                  </a>
                </div>
              </div>

              {/* <a href="#"
               class="scroll-to-top"><i class="fa fa-arrow-up"></i>
              </a> */}

              <a class="backtop js-scroll-trigger" href="#">
                <i class="fa fa-arrow-up"></i>
              </a>

              {/* <a
                id="back-to-top"
                href="#"
                class="btn circle fixed-bottom"
                role="button"
              >
                
              </a> */}

            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
