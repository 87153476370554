import React, { useEffect, useState } from "react";
import { PrivacypolicyAPI } from "./PrivacypolicyAPI";
import parse from 'html-react-parser';
import Skeletonloader from "../skeletonloader/skeletonloader";


const PrivacyPolicy = () => {

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    PrivacypolicyAPI.get()
    .then((res) => {
        console.log(res.data.data.privacy)
        let response = res.data.data.privacy;
        setData(response)
        setLoading(false);
      }
    ).catch((err) => {
      console.log(err, "error message")
      setLoading(true);
    })
  },[])


  return (
    <>
      <section className="confirmation mt-5 mb-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="confirmation-content">
                <i className="icofont-check-circled text-lg text-color-2" />
                <h2 className="mt-3 mb-4">Privacy Policy</h2>
                {loading ? (
                <>
                <Skeletonloader/>
                </>
                ):(
                  <>
                  <p className="text-justify">{parse(data.description? data.description:'')}</p>
                  </>
                )
              }
                
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicy;
