import React, { useState, useEffect, createContext } from "react";
import { UserprofileAPI } from "../Userprofile/UseprofileAPI";

export const CanSeeProfileContext = createContext();

export const CanSeeProfileProvider = (props) => {

  const [subscribedpackage, setSubscribedpackage] = useState(null);

  console.log("Subscribed package", subscribedpackage)

  return (
    <CanSeeProfileContext.Provider value={[subscribedpackage, setSubscribedpackage]}>
      {props.children}
    </CanSeeProfileContext.Provider>
  );
}