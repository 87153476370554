import React, { useState, useEffect } from "react";
import Banner from "../Banner/banner";
import "./Package.css"
import { PackageAPI } from "./PackageAPI";
import Packageloader from './Packageloader';
const Package = () => {

  const [datas, setDatas] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    PackageAPI.get()
      .then((res) => {
        console.log(res.data.data.packages)
        let response = res.data.data.packages;
        setDatas(response)
        setLoading(false);
      }
      ).catch((err) => {
        console.log(err, "error message")
        setLoading(false);
      })
  }, [])

  return (
    <>
      <Banner subtitle={"Package"} title={"Package"} />
      <div className="container section-5">
        <div className="row  ">
          {loading ? 
          <Packageloader /> :
            <>
              {datas.map((data) => (
                  <>
                    <div className="col-md-4">
                      <div className="end-card">
                        <ul className="pricing-card">
                          <li className="card-header">{data.title}</li>
                          <li className="price-card-body"><b>{data.package_att_no}</b> Package att no</li>
                          <li className="price-card-body"><b>1o</b> Emails</li>
                          <li className="price-card-body"><b>10</b> Domains</li>
                          <li className="price-card-body"><b>Endless</b> Support</li>
                          <li className="card-price">
                            <div className="money">Rs. {data.price}</div>
                            <div className="duration">per month</div>
                          </li>
                          <li className="card-footer">
                            <a href="">
                              <button className="bottom-btn">
                                Buy Now
                              </button>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </>
                )
                )
              }
            </>
          }
        </div>
      </div>
    </>
  );
};

export default Package;
