import React from "react";
import { Link } from "react-router-dom";
import "./Otp.css";
import Banner from "../Banner/banner";
const Otp = () => {
  return (
    <>
      <Banner subtitle={"Verify"} title={"Change OTP"} />
      <div>
        <form className="form-inline mt-5 mb-5">
          <div className="form-group mx-sm-3 ">
            <label htmlFor="inputPassword2" className="sr-only">
              Enter OTP
            </label>
            <input
              type="password"
              className="form-control"
              id="inputPassword2"
              placeholder="Enter OTP"
            />
          </div>
         
        </form>
        <div className="verify-btn">
        <Link to={"/Password"}>
            <button type="submit" className="btn-main4 mb-2">
              Verify
            </button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Otp;
