import axios from "axios"

export const RegisterrequestAPI={
    verify:function(data){
       return axios({
            method:"POST",
            url:`${process.env.REACT_APP_API_URL}/user/verifyOTP`,
            data:data
        })
    }
}


    