import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

export default function Userprofileloader() {
    return (
        <>
            <div className='row'>
                <div className='col-lg-3 col-md-3 col-sm-12'>
                    <Stack spacing={1}>
                        <Skeleton variant="rectangular" width={270} height={300} />
                        <Skeleton variant="text" width={270} />
                    </Stack>
                </div>
                <div className='col-lg-3 col-md-3 col-sm-12'>
                    <Stack spacing={1}>
                        <Skeleton variant="rectangular" width={270} height={300} />
                        <Skeleton variant="text" width={270} />
                    </Stack>
                </div>
                <div className='col-lg-3 col-md-3 col-sm-12'>
                    <Stack spacing={1}>
                        <Skeleton variant="rectangular" width={270} height={300} />
                        <Skeleton variant="text" width={270} />
                    </Stack>
                </div>
                <div className='col-lg-3 col-md-3 col-sm-12'>
                    <Stack spacing={1}>
                        <Skeleton variant="rectangular" width={270} height={300} />
                        <Skeleton variant="text" width={270} />
                    </Stack>
                </div>
            </div>
            <div className='row mt-5'>
                <div className='col-lg-3 col-md-3 col-sm-12'>
                    <Stack spacing={1}>
                        <Skeleton variant="rectangular" width={270} height={300} />
                        <Skeleton variant="text" width={270} />
                    </Stack>
                </div>
                <div className='col-lg-3 col-md-3 col-sm-12'>
                    <Stack spacing={1}>
                        <Skeleton variant="rectangular" width={270} height={300} />
                        <Skeleton variant="text" width={270} />
                    </Stack>
                </div>
                <div className='col-lg-3 col-md-3 col-sm-12'>
                    <Stack spacing={1}>
                        <Skeleton variant="rectangular" width={270} height={300} />
                        <Skeleton variant="text" width={270} />
                    </Stack>
                </div>
                <div className='col-lg-3 col-md-3 col-sm-12'>
                    <Stack spacing={1}>
                        <Skeleton variant="rectangular" width={270} height={300} />
                        <Skeleton variant="text" width={270} />
                    </Stack>
                </div>
            </div>
        </>
    )
}
