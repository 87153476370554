import React, { useState } from 'react'
import ReactCodeInput from 'react-code-input'
import { LoadingButton } from '@mui/lab'
import Banner from '../Banner/banner'
import { useSnackbar } from 'notistack';
import { RegisterrequestAPI } from './RegisterrequestAPI'
import { Link, useNavigate, useLocation } from 'react-router-dom'

export default function Registerrequest(setMessage) {

    const [otp, setOtp] = useState('')
    const location = useLocation();
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar();
    const [response, setResponse] = useState({
        success: null,
        error: null,
    })

    const postVerify = async (e) => {
        e.preventDefault();
        setLoading(true)
        if (otp === '') {
            setLoading(false)
        }
        else {
            await RegisterrequestAPI.verify({
                ...location.state,
                verification_code: otp,
            }).then(res => {
                if (res.data.status_code == 200) {
                    navigate('/')
                    setOtp('')
                    setLoading(false)
                } 
                else {
                    // setMessage(res.data.message)
                    setLoading(false)
                    enqueueSnackbar(res.data.message, { variant: 'error' });
                }
            }).catch(error => {
                if (error.response) {
                    enqueueSnackbar(error.res.data.message, { variant: 'error' });
                    setLoading(false)
                }
                else {
                    enqueueSnackbar('Server Error', { variant: 'error' });
                    setLoading(false)
                }
            })

        }
        setTimeout(() => {
            setResponse({
                success: null,
                error: null,
            });
        }, 5000);
    }


    return (
        <>
            <Banner subtitle={"Verify"} title={"Register Request"} />
            <div>
                <form className="form-inline mt-5 mb-5">
                    <div className="form-group mx-sm-3 ">
                        <label htmlFor="inputPassword2" className="sr-only">
                            Enter OTP
                        </label>
                        <div className="row">
                            <ReactCodeInput
                                name="resetPassword"
                                inputMode="numeric"
                                fields={4}
                                type="text"
                                value={otp}
                                onChange={(e) => setOtp(e)}
                            />
                        </div>
                    </div>

                </form>
                <div className='verify-btn'>
                    <Link to='/'>
                        <LoadingButton type="submit" className="btn btn-main btn-round-full" loading={loading} onClick={postVerify}>
                            Verify&nbsp;<i class="fa-solid fa-angle-right"></i>
                        </LoadingButton>
                    </Link>

                </div>
            </div>

        </>
    )
}
