import React, {useEffect, useState} from "react";
import "./Contact.css"
import { FooterAPI } from "../Footer/FooterAPI";
import parse from 'html-react-parser'
import Maploader from '../Footer/Maploader';
import Skeleton from '@mui/material/Skeleton';

const Contact = () => {

  const [data, setData] = useState({});
  const [loading, setLoading] =useState(false);

  useEffect(() => {
setLoading(true);
    FooterAPI.get()
      .then((res) => {
        // console.log(res.data.data.setting)
        let response = res.data.data.setting;
        setData(response)
        setLoading(false);
      }
      ).catch((err) => {
        console.log(err, "error message")
        setLoading(false);
      })
  },[])


  return (
    <>
      <div>
        <section className="page-title bg-1 mt-3">
          <div className="overlay" />
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="block text-center">
                  <span className="text-white">Contact Us</span>
                  <h1 className="text-capitalize mb-5 text-lg">Get in Touch</h1>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* contact form start */}
        <section className="section-3 contact-info pb-0 ">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-sm-6 col-md-6">
                <div className="contact-block mb-4 mb-lg-0">
                <i class="fa-solid fa-headset"></i>
                  <h5>Call Us</h5>
                  {loading? <Skeleton/> :  <>{data.contact1}</>}
                 
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 col-md-6">
                <div className="contact-block mb-4 mb-lg-0">
                <i class="fa-solid fa-envelopes-bulk"></i>
                  <h5>Email Us</h5>
                  {loading? <Skeleton/> : <>{data.email1}</>}
                 
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 col-md-6">
                <div className="contact-block mb-4 mb-lg-0">
                <i class="fa-solid fa-location-dot"></i>
                  <h5>Location</h5>
                  {loading? <Skeleton/> : <>{data.address}</>}
                 
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="contact-form-wrap section-3 ">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="section-title text-center">
                  <h2 className="text-md mb-2">Contact us</h2>
                  <div className="divider2 mx-auto my-4" />
                  <p className="mb-5">
                    Laboriosam exercitationem molestias beatae eos pariatur,
                    similique, excepturi mollitia sit perferendis maiores
                    ratione aliquam?
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <form
                  id="contact-form"
                  className="contact__form "
                  method="post"
                  action="mail.php"
                >
                  {/* form message */}
                  <div className="row">
                    <div className="col-12">
                      <div
                        className="alert alert-success contact__msg"
                        style={{ display: "none" }}
                        role="alert"
                      >
                        Your message was sent successfully.
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          name="name"
                          id="name"
                          type="text"
                          className="form-control"
                          placeholder="Your Full Name"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          name="email"
                          id="email"
                          type="email"
                          className="form-control"
                          placeholder="Your Email Address"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          name="subject"
                          id="subject"
                          type="text"
                          className="form-control"
                          placeholder="Your Query Topic"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          name="phone"
                          id="phone"
                          type="text"
                          className="form-control"
                          placeholder="Your Phone Number"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group-2 mb-4">
                    <textarea
                      name="message"
                      id="message"
                      className="form-control"
                      rows={8}
                      placeholder="Your Message"
                      defaultValue={""}
                    />
                  </div>
                  <div className="text-center">
                    <input
                      className="btn btn-main btn-round-full"
                      name="submit"
                      type="submit"
                      defaultValue="Send Messege"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        <div className="google-map mt-5 ">
          {loading? <Maploader/> : <>
          {parse(data.google_location ? data.google_location : '')}
          </>}
         
        </div>
      </div>
    </>
  );
};

export default Contact;
