import React from 'react'
import Footer from '../components/Footer/Footer'
import Header from '../components/Header/Header'
import Otp from '../components/Otp/Otp'

const OtpPage = () => {
  return (
    <>
    <Header/>
    <Otp/>
    <Footer/>
    </>
  )
}

export default OtpPage