import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

export default function Variants() {
  return (
      <>
    <Stack spacing={1} >
      <Skeleton variant="text" />
      <Skeleton variant="rectangle" width={300} height={50} />
      <Skeleton variant="rectangular" width={300} height={250} />
    </Stack>
     
    </>
  );
}
