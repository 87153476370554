import React from 'react'
import Footer from '../components/Footer/Footer'
import Header from '../components/Header/Header'
import Disclaimer from '../components/Disclaimer/Disclaimer'
const DisclaimerPage = () => {
  return (
   <>
   <Header/>
   <Disclaimer/>
   <Footer/>
   </>
  )
}

export default DisclaimerPage