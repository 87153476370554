import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { UserRegisterAPI } from "./UserRegisterAPI";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from 'notistack';

const UserRegister = () => {

  const [phone, setPhone] = useState("")
  const [password, setPassword] = useState("")
  const [confirm_password, setConfirmpassword] = useState('')
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate()

  const postRegister = async (e) => {
    e.preventDefault();
    if (phone === '' || password === '') {
      return
    }
    else {
      setLoading(true)
      await UserRegisterAPI.register({
        phone_no: phone,
        password: password
      }).then(res => {
        if (res.data.status === true) {
          navigate('/registerrequest', {
            state: {
              phone_no: phone,
              password: password,
            },
          })
          enqueueSnackbar('OTP has been sent to your number', { variant: 'success' });
          setPhone('')
          setPassword('')
        }
      }).catch(error => {
        console.log(e.message)
      })
      setLoading(false)
    }
    setTimeout(() => {
    }, 5000)

  }


  return (
    <>
      <div>
        <section className="page-title bg-1 mt-3">
          <div className="overlay" />
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="block text-center">
                  <span className="text-white">It's simple and quick</span>
                  <h1 className="text-capitalize mb-5 text-lg">
                    Get your journey started
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="appoinment mt-3 mb-4">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="appoinment-wrap  mt-lg-0 pl-lg-5">
                  <form
                    id="#"
                    className="appoinment-form"
                    method="post"
                    action="#"
                  >
                    {/* <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <input
                            name="phone"
                            id="phone"
                            type="tel"
                            className="form-control"
                            placeholder="Phone Number"
                            maxlength={10}
                            minLength={10}
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">

                        <div className="form-group">
                          <input
                            name="password"
                            id="password"
                            type="password"
                            className="form-control"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </div>

                      </div>
                    </div> */}


                    <div className="row">
                      <div className="offset-lg-3 offset-md-3">
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <form>
                          <div class="form-group row">
                            <label for="inputPassword" class="col-sm-2 col-form-label">
                              Phone Number
                            </label>
                            <div class="col-sm-10">
                              <input
                                type="text"
                                class="form-control"
                                id="inputPassword"
                                placeholder="Phone Number"
                                maxlength={10}
                                minLength={10}
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                              />
                            </div>
                          </div>
                          <div class="form-group row">
                            <label for="inputPassword" class="col-sm-2 col-form-label">
                              Password
                            </label>
                            <div class="col-sm-10">
                              <input
                                type="password"
                                class="form-control"
                                id="inputPassword"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                              />
                            </div>
                          </div>
                          <div class="form-group row">
                            <label for="inputPassword" class="col-sm-2 col-form-label">
                              Confirm Password
                            </label>
                            <div class="col-sm-10">
                              <input
                                type="password"
                                class="form-control"
                                id="inputPassword"
                                placeholder="Confirm Password"
                                value={confirm_password}
                                onChange={(e) => setConfirmpassword(e.target.value)}
                              />
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="offset-lg-3 offset-md-3">
                      </div>
                    </div>
                    {/* <div className="mt-4 text-center">
                      <Link to={"/"}>
                        <LoadingButton className="btn btn-main btn-round-full mb-3" type="submit" loading={loading} onClick={postRegister}>
                          Let's get Started
                        </LoadingButton>
                      </Link>
                    </div> */}
                    <div class="mt-4 text-center">
                      <Link to={"/"}>
                        <LoadingButton className="btn btn-main btn-round-full" type="submit" loading={loading} onClick={postRegister}>
                        Let's get Started&nbsp;<i class="fa-solid fa-angle-right"></i>
                        </LoadingButton>
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default UserRegister;
