import React, { useState, useEffect } from "react";
import { TermsandConditionAPI } from "./TermsandConditionAPI";
import parse from 'html-react-parser';
import Skeletonloader from "../skeletonloader/skeletonloader";

const Terms = () => {

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    TermsandConditionAPI.get()
      .then((res) => {
        console.log(res.data.data.termsAndCondition)
        let response = res.data.data.termsAndCondition;
        setData(response)
        setLoading(false);
      }
      ).catch((err) => {
        console.log(err, "error message")
        setLoading(false);
      })
  }, [])


  return (
    <>
      <section className="confirmation mt-5 mb-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="confirmation-content">
                <i className="icofont-check-circled text-lg text-color-2" />
                <h2 className="mt-3 mb-4">Terms &amp; Condition</h2>
                { loading ? (
                <>
                  <Skeletonloader />
                </>
                ) : (
                <>
                  <p className="text-justify">{parse(data.description ? data.description : '')}</p>
                </>
                )
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Terms;
