import axios from "axios";

export const UserprofileAPI = {

    get: function (user) {
        let token = localStorage.getItem("token")
        return axios({
            method: 'GET',
            url: `${process.env.REACT_APP_API_URL}/discover-match`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
    },

    getgotra: function () {
        let token = localStorage.getItem("token")
        return axios({
            method: 'GET',
            url: `${process.env.REACT_APP_API_URL}/gotras`,
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
    },

    getcaste: function () {
        let token = localStorage.getItem("token")
        return axios({
            method: 'GET',
            url: `${process.env.REACT_APP_API_URL}/castes`,
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
    },

    getreligion: function () {
        let token = localStorage.getItem("token")
        return axios({
            method: 'GET',
            url: `${process.env.REACT_APP_API_URL}/religions`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    },

    geteducation: function () {
        let token = localStorage.getItem("token")
        return axios({
            method: 'GET',
            url: `${process.env.REACT_APP_API_URL}/educations`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    },

    getemployment: function () {
        let token = localStorage.getItem("token")
        return axios({
            method: 'GET',
            url: `${process.env.REACT_APP_API_URL}/employment-types`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    },

    getcountry: function () {
        let token = localStorage.getItem("token")
        return axios({
            method: 'GET',
            url: `${process.env.REACT_APP_API_URL}/countries`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    },

    getcity: function () {
        let token = localStorage.getItem("token")
        return axios({
            method: 'GET',
            url: `${process.env.REACT_APP_API_URL}/cities`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    },


    getdetail: function (id) {
        console.log('id', id)
        let token = localStorage.getItem("token")
        return axios({
            method: 'GET',
            url: `${process.env.REACT_APP_API_URL}/profile/${id}`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    },

    getfilter: function () {
        let token = localStorage.getItem('token')
        return axios({
            method: 'GET',
            url: `${process.env.REACT_APP_API_URL}/filter-data`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    },

    viewprofile: function (id) {
        let token = localStorage.getItem('token')
        return axios({
            method: 'POST',
            url: `${process.env.REACT_APP_API_URL}/view-profile/${id}`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    },

    getprofileview: function () {
        let token = localStorage.getItem('token')
        return axios({
            method: 'POST',
            url: `${process.env.REACT_APP_API_URL}/get-profile-view`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })

    },

    checklike: function (id) {
        let token = localStorage.getItem('token')
        return axios({
            method: 'GET',
            url: `${process.env.REACT_APP_API_URL}/has-liked/${id}`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    },

    postlike: function (id) {
        console.log('id', id)
        let token = localStorage.getItem('token')
        return axios({
            method: 'POST',
            url: `${process.env.REACT_APP_API_URL}/like/create`,
            data: {
                'liked_to': id
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    },

    subscribepackage: function () {
        let token = localStorage.getItem('token')
        return axios({
            method: 'POST',
            url: `${process.env.REACT_APP_API_URL}/user/getUserByToken`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
        )
    }

}
