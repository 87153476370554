import React from 'react'
import Footer from '../components/Footer/Footer'
import Header from '../components/Header/Header'
import Registerrequest from '../components/Registerrequest/Registerrequest'

export default function RegisterrequestPage() {
  return (
    <>
    <Header/>
    <Registerrequest/>
    <Footer/>
    </>
  )
}
