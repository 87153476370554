import './App.css'
import React, {useState, useEffect, useContext} from 'react';
import HomePage from './Pages/HomePage';
import UserProfilePage from './Pages/UserProfilePage';
import AboutPage from './Pages/AboutPage';
import SuccessStoriesPage from './Pages/SuccessStoriesPage';
import ContactPage from './Pages/ContactPage';
import TermsandConditionPage from './Pages/TermsandConditionPage';
import PrivacyPolicyPage from './Pages/PrivacyPolicyPage';
import DisclaimerPage from './Pages/DisclaimerPage';
import ProfileDetailPage from './Pages/ProfileDetailPage';
import UserRegisterPage from './Pages/UserRegisterPage';
import ChangepasswordPage from './Pages/ChangepasswordPage';
import PasswordPage from './Pages/PasswordPage';
import OtpPage from './Pages/OtpPage';
import { Routes, Route, Navigate } from 'react-router-dom'
import ScrollToTop from './helper/Scrolltotop';
import Myprofilepage from './Pages/Myprofilepage';
import Packagepage from './Pages/Packagepage';
import Registerrequest from './components/Registerrequest/Registerrequest';
import RegisterrequestPage from './Pages/RegisterrequestPage';
import { UserContext } from './components/Context/UserContext';
import { UserprofileAPI } from './components/Userprofile/UseprofileAPI';
import ProfileDetail from './components/ProfileDetail/ProfileDetail';
import { CanSeeProfileContext } from './components/Context/CanSeeProfileContext';


function App() {

  const[subscribedpackage,setSubscribedpackage]=useContext(CanSeeProfileContext)
  const [user, setUser] = useContext(UserContext);
 

  return (
    <>
      <ScrollToTop />

      <Routes>

        <Route path='/' element={<HomePage />} />
        <Route path='/UserProfile' element={<UserProfilePage />} />
        <Route path='/Myprofile' element={<Myprofilepage />} />
        <Route path='/Package' element={<Packagepage />} />
        <Route path='/About' element={<AboutPage />} />
        <Route path='/SuccessStories' element={<SuccessStoriesPage />} />
        <Route path='/Contact' element={<ContactPage />} />
        <Route path='/TermsandConditions' element={<TermsandConditionPage />} />
        <Route path='/PrivacyPolicy' element={<PrivacyPolicyPage />} />
        <Route path='/Disclaimer' element={<DisclaimerPage />} />
        {/* <Route path='/ProfileDetail' element={<ProfileDetailPage />} /> */}
        <Route path='/UserRegister' element={<UserRegisterPage />} />
        <Route path='/ChangePassword' element={<ChangepasswordPage />} />
        <Route path='/Password' element={<PasswordPage />} /> 
        <Route path='/Otp' element={<OtpPage />} />
        <Route path='/Registerrequest' element={<RegisterrequestPage />} />

        {/* <Route path='/ProfileDetail/:id' element={canSeeProfile ? <ProfileDetailPage/> : <Navigate to="/UserProfile" /> } /> */}

        <Route path='/ProfileDetail/:id' element={subscribedpackage && (subscribedpackage.no_of_profile_viewed < subscribedpackage.number) ? <ProfileDetailPage/> : <Navigate to="/UserProfile" /> } />
      </Routes>

    </>
  );
}

export default App;
