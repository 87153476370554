import React from 'react'
import Footer from '../components/Footer/Footer'
import Header from '../components/Header/Header'
import Password from '../components/Password/Password'


const PasswordPage = () => {
  return (
   <>
   <Header/>
   <Password/>
   <Footer/>
   </>
  )
}

export default PasswordPage