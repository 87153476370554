import React from 'react'
import Header from '../components/Header/Header'
import Contact from '../components/Contact/Contact'
import Footer from '../components/Footer/Footer'
const ContactPage = () => {
  return (
    <>
    <Header/>
    <Contact/>
    <Footer/>
    </>
  )
}

export default ContactPage