import React from "react";
import { Link } from "react-router-dom";
import './Password.css'
import Banner from "../Banner/banner"
import LoadingButton from "@mui/lab/LoadingButton";

const Password = () => {
  return (
    <>
      <Banner subtitle={"Password"} title={"Password"} />
      <div className="container-fluid password  mb-5 ">
        <div className="row">
          <div className="offset-lg-3 offset-md-3">
          </div>
          <div className="col-lg-6 col-md-6">
            <form>
              <div class="form-group row">
                <label for="inputPassword" class="col-sm-2 col-form-label">
                  Phone Number
                </label>
                <div class="col-sm-10">
                  <input
                    type="password"
                    class="form-control"
                    id="inputPassword"
                    placeholder="Phone Number"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label for="inputPassword" class="col-sm-2 col-form-label">
                  Password
                </label>
                <div class="col-sm-10">
                  <input
                    type="password"
                    class="form-control"
                    id="inputPassword"
                    placeholder="Password"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label for="inputPassword" class="col-sm-2 col-form-label">
                  Confirm Password
                </label>
                <div class="col-sm-10">
                  <input
                    type="password"
                    class="form-control"
                    id="inputPassword"
                    placeholder="Confirm Password"
                  />
                 

                  <div className="mt-4 text-center">
                    <Link to={"/"}>
                      <LoadingButton className="btn btn-main btn-round-full mb-3" type="submit" >
                        Let's get Started
                        
                      </LoadingButton>
                    </Link>
                  </div>
                </div>
              </div>
            </form>

          </div>
          <div className="offset-lg-3 offset-md-3">

          </div>

        </div>
      </div>

    </>
  );
};

export default Password;
